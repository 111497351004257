import React from "react"
import { Layout } from "~/components"
import { Alert, Container } from "reactstrap"
import styled from "styled-components"

const ThankYou = () => {
	return (
		<Layout>
			<Container>
				<ThankYouContainer>
					<Alert color="primary">
						Thank you for messaging us. One of our personnel will keep in touch
						with you soon.
					</Alert>
				</ThankYouContainer>
			</Container>
		</Layout>
	)
}

export default ThankYou

const ThankYouContainer = styled.div`
	padding: 100px 0;
`
